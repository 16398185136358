// This file is automatically generated by internal/scripts/refresh-assets.ts.
// Do not edit manually. Or do, I'm a comment, not a cop.

/** @internal */
export const DEFAULT_TRANSLATION = {
	'action.convert-to-bookmark': 'Convert to Bookmark',
	'action.convert-to-embed': 'Convert to Embed',
	'action.open-embed-link': 'Open link',
	'action.align-bottom': 'Align bottom',
	'action.align-center-horizontal': 'Align horizontally',
	'action.align-center-vertical': 'Align vertically',
	'action.align-center-horizontal.short': 'Align H',
	'action.align-center-vertical.short': 'Align V',
	'action.align-left': 'Align left',
	'action.align-right': 'Align right',
	'action.align-top': 'Align top',
	'action.back-to-content': 'Back to content',
	'action.bring-forward': 'Bring forward',
	'action.bring-to-front': 'Bring to front',
	'action.copy-as-json.short': 'JSON',
	'action.copy-as-json': 'Copy as JSON',
	'action.copy-as-png.short': 'PNG',
	'action.copy-as-png': 'Copy as PNG',
	'action.copy-as-svg.short': 'SVG',
	'action.copy-as-svg': 'Copy as SVG',
	'action.copy': 'Copy',
	'action.cut': 'Cut',
	'action.delete': 'Delete',
	'action.unlock-all': 'Unlock all',
	'action.distribute-horizontal': 'Distribute horizontally',
	'action.distribute-vertical': 'Distribute vertically',
	'action.distribute-horizontal.short': 'Distribute H',
	'action.distribute-vertical.short': 'Distribute V',
	'action.duplicate': 'Duplicate',
	'action.edit-link': 'Edit link',
	'action.exit-pen-mode': 'Exit pen mode',
	'action.export-as-json.short': 'JSON',
	'action.export-as-json': 'Export as JSON',
	'action.export-as-png.short': 'PNG',
	'action.export-as-png': 'Export as PNG',
	'action.export-as-svg.short': 'SVG',
	'action.export-as-svg': 'Export as SVG',
	'action.export-all-as-json.short': 'JSON',
	'action.export-all-as-json': 'Export as JSON',
	'action.export-all-as-png.short': 'PNG',
	'action.export-all-as-png': 'Export as PNG',
	'action.export-all-as-svg.short': 'SVG',
	'action.export-all-as-svg': 'Export as SVG',
	'action.fit-frame-to-content': 'Fit to content',
	'action.flip-horizontal': 'Flip horizontally',
	'action.flip-vertical': 'Flip vertically',
	'action.flip-horizontal.short': 'Flip H',
	'action.flip-vertical.short': 'Flip V',
	'action.fork-project': 'Fork this project',
	'action.fork-project-on-tldraw': 'Fork project on tldraw',
	'action.group': 'Group',
	'action.insert-embed': 'Insert embed',
	'action.insert-media': 'Upload media',
	'action.leave-shared-project': 'Leave shared project',
	'action.new-project': 'New project',
	'action.new-shared-project': 'New shared project',
	'action.open-cursor-chat': 'Cursor chat',
	'action.open-file': 'Open file',
	'action.pack': 'Pack',
	'action.paste': 'Paste',
	'action.paste-error-title': 'Pasting failed',
	'action.paste-error-description':
		'Could not paste due to missing clipboard permissions. Please enable the permissions and try again.',
	'action.print': 'Print',
	'action.redo': 'Redo',
	'action.remove-frame': 'Remove frame',
	'action.rename': 'Rename',
	'action.rotate-ccw': 'Rotate counterclockwise',
	'action.rotate-cw': 'Rotate clockwise',
	'action.save-copy': 'Save a copy',
	'action.select-all': 'Select all',
	'action.select-none': 'Select none',
	'action.send-backward': 'Send backward',
	'action.send-to-back': 'Send to back',
	'action.share-project': 'Share this project',
	'action.stack-horizontal': 'Stack horizontally',
	'action.stack-vertical': 'Stack vertically',
	'action.stack-horizontal.short': 'Stack H',
	'action.stack-vertical.short': 'Stack V',
	'action.stop-following': 'Stop following',
	'action.stretch-horizontal': 'Stretch horizontally',
	'action.stretch-vertical': 'Stretch vertically',
	'action.stretch-horizontal.short': 'Stretch H',
	'action.stretch-vertical.short': 'Stretch V',
	'action.toggle-auto-size': 'Toggle auto size',
	'action.toggle-dark-mode.menu': 'Dark mode',
	'action.toggle-dark-mode': 'Toggle dark mode',
	'action.toggle-paste-at-cursor.menu': 'Paste at cursor',
	'action.toggle-paste-at-cursor': 'Toggle paste at cursor',
	'action.toggle-wrap-mode.menu': 'Select on wrap',
	'action.toggle-wrap-mode': 'Toggle Select on wrap',
	'action.toggle-reduce-motion.menu': 'Reduce motion',
	'action.toggle-reduce-motion': 'Toggle reduce motion',
	'action.toggle-edge-scrolling.menu': 'Edge scrolling',
	'action.toggle-edge-scrolling': 'Toggle edge scrolling',
	'action.toggle-debug-mode.menu': 'Debug mode',
	'action.toggle-debug-mode': 'Toggle debug mode',
	'action.toggle-focus-mode.menu': 'Focus mode',
	'action.toggle-focus-mode': 'Toggle focus mode',
	'action.toggle-dynamic-size-mode.menu': 'Dynamic size',
	'action.toggle-dynamic-size-mode': 'Toggle dynamic size',
	'action.toggle-grid.menu': 'Show grid',
	'action.toggle-grid': 'Toggle grid',
	'action.toggle-lock': 'Toggle locked',
	'action.flatten-to-image': 'Flatten',
	'action.toggle-snap-mode.menu': 'Always snap',
	'action.toggle-snap-mode': 'Toggle always snap',
	'action.toggle-tool-lock.menu': 'Tool lock',
	'action.toggle-tool-lock': 'Toggle tool lock',
	'action.toggle-transparent.context-menu': 'Transparent',
	'action.toggle-transparent.menu': 'Transparent',
	'action.toggle-transparent': 'Toggle transparent background',
	'action.undo': 'Undo',
	'action.ungroup': 'Ungroup',
	'action.zoom-in': 'Zoom in',
	'action.zoom-out': 'Zoom out',
	'action.zoom-to-100': 'Zoom to 100%',
	'action.zoom-to-fit': 'Zoom to fit',
	'action.zoom-to-selection': 'Zoom to selection',
	'assets.files.size-too-big': 'File size is too big',
	'assets.files.type-not-allowed': 'File type is not allowed',
	'assets.files.upload-failed': 'Upload failed',
	'assets.url.failed': "Couldn't load URL preview",
	'theme.dark': 'Dark',
	'theme.light': 'Light',
	'theme.system': 'System',
	'color-style.white': 'White',
	'color-style.black': 'Black',
	'color-style.blue': 'Blue',
	'color-style.green': 'Green',
	'color-style.grey': 'Grey',
	'color-style.light-blue': 'Light blue',
	'color-style.light-green': 'Light green',
	'color-style.light-red': 'Light red',
	'color-style.light-violet': 'Light violet',
	'color-style.orange': 'Orange',
	'color-style.red': 'Red',
	'color-style.violet': 'Violet',
	'color-style.yellow': 'Yellow',
	'fill-style.none': 'None',
	'document.default-name': 'Untitled',
	'fill-style.semi': 'Semi',
	'fill-style.solid': 'Solid',
	'fill-style.pattern': 'Pattern',
	'fill-style.fill': 'Fill',
	'dash-style.dashed': 'Dashed',
	'dash-style.dotted': 'Dotted',
	'dash-style.draw': 'Draw',
	'dash-style.solid': 'Solid',
	'size-style.s': 'Small',
	'size-style.m': 'Medium',
	'size-style.l': 'Large',
	'size-style.xl': 'Extra large',
	'opacity-style.0.1': '10%',
	'opacity-style.0.25': '25%',
	'opacity-style.0.5': '50%',
	'opacity-style.0.75': '75%',
	'opacity-style.1': '100%',
	'font-style.draw': 'Draw',
	'font-style.sans': 'Sans',
	'font-style.serif': 'Serif',
	'font-style.mono': 'Mono',
	'align-style.start': 'Start',
	'align-style.middle': 'Middle',
	'align-style.end': 'End',
	'align-style.justify': 'Justify',
	'verticalAlign-style.start': 'Top',
	'verticalAlign-style.middle': 'Middle',
	'verticalAlign-style.end': 'Bottom',
	'geo-style.arrow-down': 'Arrow down',
	'geo-style.arrow-left': 'Arrow left',
	'geo-style.arrow-right': 'Arrow right',
	'geo-style.arrow-up': 'Arrow up',
	'geo-style.diamond': 'Diamond',
	'geo-style.ellipse': 'Ellipse',
	'geo-style.hexagon': 'Hexagon',
	'geo-style.octagon': 'Octagon',
	'geo-style.oval': 'Oval',
	'geo-style.cloud': 'Cloud',
	'geo-style.pentagon': 'Pentagon',
	'geo-style.rectangle': 'Rectangle',
	'geo-style.rhombus-2': 'Rhombus 2',
	'geo-style.rhombus': 'Rhombus',
	'geo-style.star': 'Star',
	'geo-style.trapezoid': 'Trapezoid',
	'geo-style.triangle': 'Triangle',
	'geo-style.x-box': 'X box',
	'geo-style.check-box': 'Check box',
	'arrowheadStart-style.none': 'None',
	'arrowheadStart-style.arrow': 'Arrow',
	'arrowheadStart-style.bar': 'Bar',
	'arrowheadStart-style.diamond': 'Diamond',
	'arrowheadStart-style.dot': 'Dot',
	'arrowheadStart-style.inverted': 'Inverted',
	'arrowheadStart-style.pipe': 'Pipe',
	'arrowheadStart-style.square': 'Square',
	'arrowheadStart-style.triangle': 'Triangle',
	'arrowheadEnd-style.none': 'None',
	'arrowheadEnd-style.arrow': 'Arrow',
	'arrowheadEnd-style.bar': 'Bar',
	'arrowheadEnd-style.diamond': 'Diamond',
	'arrowheadEnd-style.dot': 'Dot',
	'arrowheadEnd-style.inverted': 'Inverted',
	'arrowheadEnd-style.pipe': 'Pipe',
	'arrowheadEnd-style.square': 'Square',
	'arrowheadEnd-style.triangle': 'Triangle',
	'spline-style.line': 'Line',
	'spline-style.cubic': 'Cubic',
	'tool.select': 'Select',
	'tool.hand': 'Hand',
	'tool.draw': 'Draw',
	'tool.eraser': 'Eraser',
	'tool.arrow-down': 'Arrow down',
	'tool.arrow-left': 'Arrow left',
	'tool.arrow-right': 'Arrow right',
	'tool.arrow-up': 'Arrow up',
	'tool.arrow': 'Arrow',
	'tool.cloud': 'Cloud',
	'tool.diamond': 'Diamond',
	'tool.ellipse': 'Ellipse',
	'tool.hexagon': 'Hexagon',
	'tool.highlight': 'Highlight',
	'tool.line': 'Line',
	'tool.octagon': 'Octagon',
	'tool.oval': 'Oval',
	'tool.pentagon': 'Pentagon',
	'tool.rectangle': 'Rectangle',
	'tool.rhombus': 'Rhombus',
	'tool.star': 'Star',
	'tool.trapezoid': 'Trapezoid',
	'tool.triangle': 'Triangle',
	'tool.x-box': 'X box',
	'tool.check-box': 'Check box',
	'tool.asset': 'Asset',
	'tool.frame': 'Frame',
	'tool.note': 'Note',
	'tool.laser': 'Laser',
	'tool.embed': 'Embed',
	'tool.text': 'Text',
	'tool.pointer-down': 'Pointer down',
	'menu.title': 'Menu',
	'menu.theme': 'Theme',
	'menu.copy-as': 'Copy as',
	'menu.edit': 'Edit',
	'menu.export-as': 'Export as',
	'menu.file': 'File',
	'menu.language': 'Language',
	'menu.preferences': 'Preferences',
	'menu.help': 'Help',
	'menu.view': 'View',
	'context-menu.edit': 'Edit',
	'context-menu.arrange': 'Arrange',
	'context-menu.copy-as': 'Copy as',
	'context-menu.export-as': 'Export as',
	'context-menu.export-all-as': 'Export',
	'context-menu.move-to-page': 'Move to page',
	'context-menu.reorder': 'Reorder',
	'page-menu.title': 'Pages',
	'page-menu.create-new-page': 'Create new page',
	'page-menu.max-page-count-reached': 'Max pages reached',
	'page-menu.new-page-initial-name': 'Page 1',
	'page-menu.edit-start': 'Edit',
	'page-menu.edit-done': 'Done',
	'page-menu.go-to-page': 'Go to page',
	'page-menu.submenu.rename': 'Rename',
	'page-menu.submenu.duplicate-page': 'Duplicate',
	'page-menu.submenu.title': 'Menu',
	'page-menu.submenu.move-down': 'Move down',
	'page-menu.submenu.move-up': 'Move up',
	'page-menu.submenu.delete': 'Delete',
	'share-menu.title': 'Share',
	'share-menu.save-note': 'Download this project to your computer as a .tldr file.',
	'share-menu.fork-note': 'Create a new shared project based on this snapshot.',
	'share-menu.share-project': 'Share this project',
	'share-menu.default-project-name': 'Shared Project',
	'share-menu.copy-link': 'Copy editor link',
	'share-menu.readonly-link': 'Read-only',
	'share-menu.create-snapshot-link': 'Copy snapshot link',
	'share-menu.snapshot-link-note': 'Capture and share this project as a read-only snapshot link.',
	'share-menu.copy-readonly-link': 'Copy viewer link',
	'share-menu.offline-note': 'Create a new shared project based on your current project.',
	'share-menu.copy-link-note': 'Anyone with the link will be able to view and edit this project.',
	'share-menu.copy-readonly-link-note': 'Anyone with the link will be able to access this project.',
	'share-menu.project-too-large':
		"Sorry, this project can't be shared because it's too large. We're working on it!",
	'share-menu.upload-failed':
		"Sorry, we couldn't upload your project at the moment. Please try again or let us know if the problem persists.",
	'share-menu.creating-project': 'Creating the new project…',
	'share-menu.copied': 'Copied link',
	'document-name-menu.copy-link': 'Copy link',
	'status.offline': 'Offline',
	'status.online': 'Online',
	'people-menu.title': 'People',
	'people-menu.change-name': 'Change name',
	'people-menu.change-color': 'Change color',
	'people-menu.follow': 'Following',
	'people-menu.following': 'Following',
	'people-menu.leading': 'Following You',
	'people-menu.user': '(You)',
	'people-menu.invite': 'Invite others',
	'help-menu.title': 'Help and resources',
	'help-menu.about': 'About tldraw',
	'help-menu.docs': 'Documentation & API',
	'help-menu.discord': 'Discord',
	'help-menu.github': 'GitHub',
	'help-menu.keyboard-shortcuts': 'Keyboard shortcuts',
	'help-menu.twitter': 'Twitter',
	'help-menu.terms': 'Terms of service',
	'help-menu.privacy': 'Privacy policy',
	'actions-menu.title': 'Actions',
	'edit-link-dialog.title': 'Edit link',
	'edit-link-dialog.invalid-url': 'A link must be a valid URL.',
	'edit-link-dialog.detail': 'Links will open in a new tab.',
	'edit-link-dialog.url': 'URL',
	'edit-link-dialog.clear': 'Clear',
	'edit-link-dialog.save': 'Continue',
	'edit-link-dialog.cancel': 'Cancel',
	'embed-dialog.title': 'Insert embed',
	'embed-dialog.back': 'Back',
	'embed-dialog.create': 'Create',
	'embed-dialog.cancel': 'Cancel',
	'embed-dialog.url': 'URL',
	'embed-dialog.instruction': "Paste in the site's URL to create the embed.",
	'embed-dialog.invalid-url': 'We could not create an embed from that URL.',
	'edit-pages-dialog.move-down': 'Move down',
	'edit-pages-dialog.move-up': 'Move up',
	'shortcuts-dialog.title': 'Keyboard shortcuts',
	'shortcuts-dialog.edit': 'Edit',
	'shortcuts-dialog.file': 'File',
	'shortcuts-dialog.preferences': 'Preferences',
	'shortcuts-dialog.tools': 'Tools',
	'shortcuts-dialog.transform': 'Transform',
	'shortcuts-dialog.view': 'View',
	'shortcuts-dialog.collaboration': 'Collaboration',
	'home-project-dialog.title': 'Home project',
	'home-project-dialog.description': "This is your local home project. It's just for you!",
	'rename-project-dialog.title': 'Rename project',
	'rename-project-dialog.cancel': 'Cancel',
	'rename-project-dialog.rename': 'Rename',
	'home-project-dialog.ok': 'Ok',
	'style-panel.title': 'Styles',
	'style-panel.align': 'Align',
	'style-panel.vertical-align': 'Vertical align',
	'style-panel.position': 'Position',
	'style-panel.arrowheads': 'Arrows',
	'style-panel.arrowhead-start': 'Start',
	'style-panel.arrowhead-end': 'End',
	'style-panel.color': 'Color',
	'style-panel.dash': 'Dash',
	'style-panel.fill': 'Fill',
	'style-panel.font': 'Font',
	'style-panel.geo': 'Shape',
	'style-panel.mixed': 'Mixed',
	'style-panel.opacity': 'Opacity',
	'style-panel.size': 'Size',
	'style-panel.spline': 'Spline',
	'tool-panel.drawing': 'Drawing',
	'tool-panel.shapes': 'Shapes',
	'tool-panel.more': 'More',
	'debug-panel.more': 'More',
	'navigation-zone.toggle-minimap': 'Toggle minimap',
	'navigation-zone.zoom': 'Zoom',
	'focus-mode.toggle-focus-mode': 'Toggle focus mode',
	'toast.close': 'Close',
	'file-system.file-open-error.title': 'Could not open file',
	'file-system.file-open-error.not-a-tldraw-file':
		"The file you tried to open doesn't look like a tldraw file.",
	'file-system.file-open-error.file-format-version-too-new':
		'The file you tried to open is from a newer version of tldraw. Please reload the page and try again.',
	'file-system.file-open-error.generic-corrupted-file': 'The file you tried to open is corrupted.',
	'file-system.confirm-open.title': 'Overwrite current project?',
	'file-system.confirm-open.description':
		'Opening a file will replace your current project and any unsaved changes will be lost. Are you sure you want to continue?',
	'file-system.confirm-open.cancel': 'Cancel',
	'file-system.confirm-open.open': 'Open file',
	'file-system.confirm-open.dont-show-again': "Don't ask again",
	'file-system.confirm-clear.title': 'Clear current project?',
	'file-system.confirm-clear.description':
		'Creating a new project will clear your current project and any unsaved changes will be lost. Are you sure you want to continue?',
	'file-system.confirm-clear.cancel': 'Cancel',
	'file-system.confirm-clear.continue': 'Continue',
	'file-system.confirm-clear.dont-show-again': "Don't ask again",
	'file-system.shared-document-file-open-error.title': 'Could not open file',
	'file-system.shared-document-file-open-error.description':
		'Opening files from shared projects is not supported.',
	'sharing.confirm-leave.title': 'Leave current project?',
	'sharing.confirm-leave.description':
		'Are you sure you want to leave this shared project? You can return to it by navigating to its URL.',
	'sharing.confirm-leave.cancel': 'Cancel',
	'sharing.confirm-leave.leave': 'Leave',
	'sharing.confirm-leave.dont-show-again': "Don't ask again",
	'toast.error.export-fail.title': 'Failed export',
	'toast.error.export-fail.desc': 'Failed to export image',
	'toast.error.copy-fail.title': 'Failed copy',
	'toast.error.copy-fail.desc': 'Failed to copy image',
	'context.pages.new-page': 'New page',
	'vscode.file-open.desc':
		"We've updated this document to work with the current version of tldraw. If you'd like to keep the original version (which will work on old.tldraw.com), click below to create a backup.",
	'vscode.file-open.open': 'Continue',
	'vscode.file-open.backup': 'Backup',
	'vscode.file-open.backup-saved': 'Backup saved',
	'vscode.file-open.backup-failed': 'Backup failed: this is not a .tldr file.',
	'vscode.file-open.dont-show-again': "Don't ask again",
	'cursor-chat.type-to-chat': 'Type to chat...',
}
